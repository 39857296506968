<template>
  <div class="markingProgress">
    <refreshTime ref="refreshTime"></refreshTime>
    <template v-for="(v, i) in rateListData">
      <div v-if="showSubject(v)" :key="i" class="mark-paper-list-box">
        <div class="mark-paper-list-header">
          <span class="paper-title">
            {{ v.subjectName }}（考生人数：{{ v.examStudent }}，正常卡人数：
            <span class="err">{{ v.normalAnwserSheet }}</span
            >）
          </span>
          <span class="paper-check" @click="showDetails(v)">
            <template v-if="v.examPaperId == indexPaper.examPaperId">
              收起</template
            ><template v-else>展开</template>
          </span>
        </div>
        <div class="progress-box">
          <div class="progress-box-text">整体阅卷进度</div>
          <template v-if="!Number(v.rate)">
            <el-progress
              :text-inside="true"
              :stroke-width="100"
              :percentage="0"
            ></el-progress>
          </template>
          <template v-if="0 < Number(v.rate) && Number(v.rate) <= 1">
            <el-progress
              :text-inside="true"
              :stroke-width="100"
              :percentage="Number((v.rate * 100).toFixed(2))"
            ></el-progress>
          </template>
          <template v-if="Number(v.rate) > 1">
            <el-progress
              :text-inside="true"
              :stroke-width="100"
              :percentage="100"
            ></el-progress>
          </template>
          <div class="progress-box-text">
            {{ v.rate | setNumber }}
          </div>
        </div>
        <template v-if="v.examPaperId == indexPaper.examPaperId">
          <div class="check-box">
            <div>
              <template
                v-if="
                  v.scoringMode == 0 ||
                  (v.scoringMode == 1 && showListIndex == 2)
                "
              >
                学校：
                <el-select
                  v-model="search.schoolId"
                  placeholder=""
                  filterable
                  @change="getNewDetail()"
                >
                  <el-option label="全部学校" value="-520"> </el-option>
                  <el-option
                    v-for="item in schoolList"
                    :key="item.schoolId"
                    :label="item.schoolName"
                    :value="item.schoolId"
                  >
                  </el-option>
                </el-select>
              </template>
              <template v-if="v.scoringMode == 2 && showListIndex == 2">
                分组：
                <el-select
                  v-model="search.groupId"
                  placeholder=""
                  filterable
                  @change="getNewDetail()"
                >
                  <el-option label="全部分组" value=""> </el-option>
                  <el-option
                    v-for="item in groupList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </template>
              阅卷状态：
              <el-select
                v-model="search.readStatus"
                placeholder=""
                @change="getNewDetail()"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="item in readTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <el-button
              v-if="v.scoringMode == 1 || v.scoringMode == 2"
              type="text"
              @click="checkListType()"
            >
              <template v-if="showListIndex == 1">
                <template v-if="v.scoringMode == 1">
                  查看学校整体进度
                </template>
                <template v-else>查看分组整体进度</template>
              </template>
              <template v-if="showListIndex == 2">查看题目详情</template>
            </el-button>
          </div>
          <div v-loading="boxLoading" class="set-up-rules">
            <div
              v-if="v.scoringMode != 0 && showListIndex == 1 && showTips"
              class="tips-msg"
            >
              <div>
                <i class="el-icon-warning"></i>
                <template v-if="v.scoringMode == 1">
                  点击列表内学校名称，可查看单校阅卷详情！</template
                >
                <template v-if="v.scoringMode == 2">
                  点击列表内分组名称，可查看分组阅卷详情！</template
                >
              </div>

              <i class="el-icon-close" @click="showTips = false"></i>
            </div>
            <questionTab v-if="showListIndex == 1" ref="questionTab">
            </questionTab>
            <template v-if="showListIndex == 2">
              <schoolTab
                v-if="v.scoringMode == 1"
                ref="schoolTab"
                @checkSort="checkSort"
              ></schoolTab>
              <groupTab
                v-if="v.scoringMode == 2"
                ref="groupTab"
                @checkSort="checkSort"
              ></groupTab>
              <el-pagination
                :current-page="current"
                :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :hide-on-single-page="true"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </template>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { readTypeList } from "@/core/util/constdata";
import {
  monitorRateList,
  monitorRateDetail,
  singleWorkload,
  exportRate,
  unionRatev2,
  unionGrouprate,
  groupWorkload,
} from "@/core/api/exam/paperMonitor";
import { existexamschool } from "@/core/api/exam/examTeacher";
import { schoolGroupList } from "@/core/api/exam/examCorrectTask";
import { getStore } from "@/core/util/store";
import { showSubject, fileDownloadByUrl } from "@/core/util/util";
import { mapGetters } from "vuex";

import refreshTime from "../components/refreshTime";
import questionTab from "./components/questionTab.vue";
import schoolTab from "./components/schoolTab.vue";
import groupTab from "./components/groupTab.vue";
export default {
  name: "MarkingProgress",
  components: {
    refreshTime,
    questionTab,
    schoolTab,
    groupTab,
  },

  data() {
    return {
      showListIndex: 1,
      studentList: [],
      modelSearch: {
        schoolId: "-1",
        studentExamId: "",
      },

      showTips: true,
      boxLoading: false,
      tabLoading: false,
      showTable: false,
      dialogVisible: false,
      btnLoading: false,
      readTypeList: readTypeList,

      rateListData: [],
      indexPaper: {},
      tableData: [],
      questionTabData: [],
      titleList: [],
      schoolList: [],
      schoolId: "",

      search: {
        schoolId: "-520",
        groupId: "",
        readStatus: "",
      },
      rateSort: 0,
      current: 1,
      size: 10,
      total: 0,
      groupList: [],
    };
  },

  computed: {
    ...mapGetters(["GET_TEACHER_ROLE_LIST"]),
  },
  watch: {
    GET_TEACHER_ROLE_LIST: {
      deep: true,
      handler() {
        if (this.userInfo.roleId == 5) {
          this.setDetail();
        }
      },
    },
  },
  async created() {
    this.showListIndex = 1;
    this.userInfo = getStore({ name: "userInfo" });
    await this.getSchoollistbyexamid();
    this.monitorRateList();
    this.getschoolGroupList();
  },
  methods: {
    //联考-获取学校分组列表
    async getschoolGroupList() {
      let data = {
        examId: this.$route.query.examId,
        examPaperId: this.$route.query.examPaperId,
      };
      await schoolGroupList(data)
        .then((res) => {
          this.groupList = res.data.data;
        })
        .catch(() => {});
    },
    checkSort(val) {
      this.rateSort = val;
      this.unionRatev2();
    },
    // 分页
    handleSizeChange(val) {
      this.size = val;
      this.unionRatev2();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.unionRatev2();
    },
    checkListType() {
      this.showListIndex = this.showListIndex == 1 ? 2 : 1;
      let { scoringMode } = this.indexPaper;
      if (scoringMode == 1) {
        if (this.showListIndex == 1) {
          this.singleWorkload();
        }
      }
      if (scoringMode == 2) {
        if (this.showListIndex == 1) {
          this.groupWorkload();
        }
      }
      if (this.showListIndex == 2) {
        this.unionRatev2();
      }
    },
    async getSchoollistbyexamid() {
      const res = await existexamschool({
        examId: this.$route.query.examId,
      });

      this.schoolList = res.data.data;
    },
    exportRate() {
      let data = {
        examPaperId: this.indexPaper.examPaperId,
      };
      this.btnLoading = true;
      exportRate(data)
        .then((res) => {
          this.btnLoading = false;
          fileDownloadByUrl(res.data.data.url, res.data.data.name);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },

    showSubject(v) {
      return showSubject(v, 9);
    },

    showDetails(v) {
      if (this.indexPaper.examPaperId == v.examPaperId) {
        this.indexPaper = {};
        return;
      }
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.examPaperId = v.examPaperId;
      this.$router.push({
        query: query,
      });
      this.showListIndex = 1;
      this.indexPaper = v;
      this.getNewDetail();
    },
    // 根据权限获取列表
    setDetail() {
      if (!this.GET_TEACHER_ROLE_LIST || !this.oldTableData) {
        return;
      }

      let roleList = this.GET_TEACHER_ROLE_LIST.filter(
        (item) => item.subjectType == 8
      );

      this.tableData = [];
      if (roleList.length > 0) {
        roleList.forEach((item) => {
          this.rateListData.forEach((index) => {
            if (item.subjectId == index.subjectId) {
              this.tableData = this.oldTableData;
            }
          });
        });
      }
    },
    getNewDetail() {
      let { scoringMode } = this.indexPaper;
      this.boxLoading = true;
      if (scoringMode == 0) {
        this.monitorRateDetail();
      }
      if (scoringMode == 1) {
        if (this.showListIndex == 1) {
          this.singleWorkload();
        }
        if (this.showListIndex == 2) {
          this.unionRatev2();
        }
      }
      if (scoringMode == 2) {
        if (this.showListIndex == 1) {
          this.groupWorkload();
        }
        if (this.showListIndex == 2) {
          this.unionRatev2();
        }
      }
    },
    getunionGrouprate(data) {
      unionGrouprate(data)
        .then((res) => {
          this.boxLoading = false;
          this.$nextTick(() => {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.$refs.groupTab[0].init(this.indexPaper, this.tableData);
          });
        })
        .catch(() => {
          this.boxLoading = false;
        });
    },
    unionRatev2() {
      let { examPaperId } = this.indexPaper;
      let data = {
        examPaperId: examPaperId,
        current: this.current,
        size: this.size,
        rateSort: this.rateSort,
      };
      Object.assign(data, this.search);
      let { scoringMode } = this.indexPaper;
      if (scoringMode == 2) {
        this.getunionGrouprate(data);
        return;
      }
      unionRatev2(data)
        .then((res) => {
          this.boxLoading = false;
          this.$nextTick(() => {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.$refs.schoolTab[0].init(this.indexPaper, this.tableData);
          });
        })
        .catch(() => {
          this.boxLoading = false;
        });
    },
    singleWorkload() {
      let { examPaperId } = this.indexPaper;
      let data = {
        examPaperId: examPaperId,
      };
      Object.assign(data, this.search);
      singleWorkload(data)
        .then((res) => {
          this.boxLoading = false;
          this.$nextTick(() => {
            this.tableData = res.data.data;
            this.$nextTick(() => {
              this.$refs.questionTab[0].init(this.indexPaper, this.tableData);
            });
          });
        })
        .catch(() => {
          this.boxLoading = false;
        });
    },
    groupWorkload() {
      let { examPaperId } = this.indexPaper;
      let data = {
        examPaperId: examPaperId,
      };
      Object.assign(data, this.search);
      groupWorkload(data)
        .then((res) => {
          this.boxLoading = false;
          this.$nextTick(() => {
            this.tableData = res.data.data;
            this.$nextTick(() => {
              this.$refs.questionTab[0].init(this.indexPaper, this.tableData);
            });
          });
        })
        .catch(() => {
          this.boxLoading = false;
        });
    },
    // 获取单科阅卷详情
    monitorRateDetail() {
      let data = {
        examPaperId: this.indexPaper.examPaperId,
      };
      Object.assign(data, this.search);
      monitorRateDetail(data)
        .then((res) => {
          this.boxLoading = false;
          this.oldTableData = res.data.data;
          //老师的情况根据权限显示
          if (this.userInfo.roleId == 5) {
            this.setDetail();
          } else {
            this.tableData = this.oldTableData;
          }
          this.$nextTick(() => {
            this.$refs.questionTab[0].init(this.indexPaper, this.tableData);
          });
        })
        .catch(() => {
          this.boxLoading = false;
        });
    },
    getNewItem() {
      this.indexPaper = {};
      this.monitorRateList();
    },
    monitorRateList() {
      let data = {
        examId: this.$route.query.examId,
      };
      Object.assign(data, this.search);
      monitorRateList(data).then((res) => {
        this.rateListData = res.data.data;
        this.rateListData.map((item) => {
          if (this.$route.query.examPaperId == item.examPaperId) {
            this.showDetails(item);
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.markingProgress {
  padding: 18px 18px 0;
  ::v-deep {
    .el-progress-bar__inner {
      border-radius: 0;
    }
  }
  .student-box {
    .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }
    .model-search-box {
      margin-bottom: 24px;
      .el-input,
      .el-select {
        width: 180px;
      }
      .el-select {
        margin-right: 24px;
      }
      .el-input {
        margin-right: 24px;
      }
    }
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #ededef;
    }
  }

  .mark-paper-list-box {
    margin-bottom: 18px;
    border: 1px solid #e4e4e4;

    ::v-deep .set-up-rules {
      .el-table__header {
        th {
          padding: 18px 0;
        }
      }
      .cell {
        padding: 0;
      }
    }

    .el-progress {
      height: 12px;
      line-height: 11px;
      width: calc(100% - 180px);
      ::v-deep .el-progress-bar__outer {
        height: 12px !important;
        border-radius: 0;
        .el-progress-bar__innerText {
          display: none;
        }
      }
    }

    .search-box {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
      }
      .el-button {
        float: right;
      }
      .check-item-box {
        display: flex;
        align-items: center;
        div {
          padding: 9px 8px;
          margin-right: 18px;
          border-radius: 4px;
          background: #c3c7cc;
          color: #ffffff;
          cursor: pointer;
        }
        .on {
          &:nth-child(1) {
            background: #fc8586;
          }
          &:nth-child(2) {
            background: #2474ed;
          }
          &:nth-child(3) {
            background: #04c391;
          }
        }
      }
      .el-select {
        margin-right: 32px;
      }
    }
    .set-up-rules {
      padding: 18px;
    }
    .tips-msg {
      background: #f3f8ff;
      border-radius: 4px;
      padding: 10px 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #262829;
      margin-bottom: 12px;
      .el-icon-close {
        cursor: pointer;
      }
      .el-icon-warning {
        color: #2474ed;
      }
    }
    .check-box {
      background: #f9fafc;
      border-top: 1px solid #dee0e7;
      border-bottom: 1px solid #dee0e7;
      padding: 6px 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-select {
        margin-right: 18px;
        width: 180px;
      }
    }
    .progress-box {
      padding: 0 18px 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .progress-box-text {
        flex-shrink: 0;
      }
    }
    .mark-paper-list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px;
      .paper-check {
        color: $primary-color;
        cursor: pointer;
        .el-icon-arrow-down {
          transition: 0.2s;
        }
        .show-i {
          transform: rotate(180deg);
          transition: 0.2s;
        }
      }
      .paper-title {
        position: relative;
        .err {
          color: #ff2b00;
        }
        // &::after {
        //   content: "";
        //   width: 4px;
        //   height: 12px;
        //   background: $primary-color;
        //   position: absolute;
        //   left: -8px;
        //   top: 4px;
        //   border-radius: 2px;
        // }
      }
    }
  }
}
</style>
