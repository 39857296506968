<template>
  <div class="schoolTab">
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column
        prop="schoolName"
        label="阅卷状态"
        width="180"
        align="center"
      >
        <template slot-scope="{ row }">
          <div
            class="tips"
            :class="{
              tips0: row.readStatus == 0,
              tips1: row.readStatus == 1,
              tips2: row.readStatus == 2,
            }"
          >
            {{ row.readStatus | setName(readTypeList) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="schoolName"
        label="学校名称"
        width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="已完成/任务量"
        width="180"
        align="center"
      >
        <template slot-scope="{ row }">
          {{ row.finished.toString() }} / {{ row.workloadAll }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="阅卷进度" align="center">
        <template #header>
          <div class="sort-box">
            <div>阅卷进度</div>
            <div>
              <i
                class="el-icon-caret-top"
                :class="{ 'i-on': $parent.rateSort == 0 }"
                @click="checkSort(0)"
              >
              </i>
              <i
                class="el-icon-caret-bottom"
                :class="{ 'i-on': $parent.rateSort == 1 }"
                @click="checkSort(1)"
              >
              </i>
            </div>
          </div>
        </template>
        <template slot-scope="{ row }">
          <el-progress
            :text-inside="true"
            :stroke-width="20"
            color="#CBE0FF"
            :percentage="Number((row.rate * 100).toFixed(2))"
          ></el-progress>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="操作" width="80" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" @click="goDateils(row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { readTypeList } from "@/core/util/constdata";
export default {
  name: "SchoolTab",
  components: {},
  data() {
    return {
      tableData: [],
      readTypeList: readTypeList,
    };
  },
  created() {},
  methods: {
    checkSort(val) {
      this.$emit("checkSort", val);
    },
    goDateils(row) {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      let data = {
        fromUrl: this.$route.name,
        examPaperId: this.paper.examPaperId,
        schoolId: row.schoolId,
      };

      this.$router.push({
        name: "schoolProgress",
        query: Object.assign(query, data),
      });
    },
    init(paper, tableData) {
      this.paper = paper;
      this.tableData = tableData;
    },
  },
};
</script>
<style scoped lang="scss">
.schoolTab {
  .sort-box {
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      display: block;
      line-height: 0.5;
      cursor: pointer;
    }
  }
  .el-progress {
    line-height: 14px;
    margin: 0 18px;
  }
  .tips {
    width: 76px;
    height: 32px;
    // background: #FFF5F0;
    line-height: 32px;
    border-radius: 4px;
    display: inline-block;
  }
  .tips0 {
    background: #fff3f3;
    color: #f56c6b;
  }
  .tips1 {
    background: #fff5f0;
    color: #e5611f;
  }
  .tips2 {
    background: #f2f6ff;
    color: #2474ed;
  }
  .i-on {
    color: #2474ed;
  }
  ::v-deep {
    .el-progress-bar__outer {
      background: #ffffff !important;
      border-radius: 4px;
      border: 1px solid #cbe0ff;
    }
    .el-progress-bar__inner {
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .el-progress-bar__innerText {
      text-align: left;
      display: block;
      line-height: 20px;
    }
  }
}
</style>
